import { useState, useCallback } from 'react';

function useModal() {
  const [isOpen, setIsOpen] = useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    showModal,
    hideModal,
  };
}

export default useModal;
