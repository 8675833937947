export const VIDEO_SIZE_LIMIT = 100 * 1024 * 1024;
export const IMAGE_SIZE_LIMIT = 10 * 1024 * 1024;
export const FILE_SIZE_LIMIT = 25 * 1024 * 1024;
export const VIDEO_SIZE_ERROR_MESSAGE =
  'Video file size must be between 20 KB and 100 MB';
export const VIDEO_TYPE_ERROR_MESSAGE = 'Your video file must be video';
export const BACKGROUND_VIDEO_SIZE_ERROR_MESSAGE =
  'Background video file size must be between 20 KB and 100 MB';
export const IMAGE_SIZE_ERROR_MESSAGE =
  'Image file size must be less than 10 MB';
export const BACKGROUND_IMAGE_SIZE_ERROR_MESSAGE =
  'Background image file size must be between 100 Bytes and 10 MB';
export const IMAGE_TYPE_ERROR_MESSAGE =
  'Your image file must be jpg, jpeg, png, or gif';
export const FILE_SIZE_ERROR_MESSAGE =
  'File size must be less than or equal to 25 MB';
export const UNSUPPORTED_TYPE_ERROR_MESSAGE =
  'Your file type is not currently supported';
export const UPLOAD_ERROR_MESSAGE =
  'Oops! Something went wrong. Please try uploading again.';
export const CURRENCY_SIGN_MAP = {
  gbp: '£',
  usd: '$',
};

export const CTA_BUTTON_CONTENT_MAX_LENGTH = 255;

// For Block Conditionals

export const NEXT_TRANSITION = 'next_transition';
export const SUBMIT_TRANSITION = 'submit_transition';
export const CONDITIONAL_TRANSITION = 'conditional_transition';
export const REDIRECT_TRANSITION = 'redirect_transition';
export const GO_TO_TRANSITION = 'internal_redirect_transition';
export const BLOCK_TRANSITION = 'block_transition';

export const REDIRECT_TRANSITION_ERROR_MESSAGE = 'URL is required';
export const GO_TO_TRANSITION_ERROR_MESSAGE = "Can't be blank";

export const PILL_TITLES = {
  [NEXT_TRANSITION]: 'Next',
  [SUBMIT_TRANSITION]: 'Submit',
  [CONDITIONAL_TRANSITION]: 'Conditional',
  [REDIRECT_TRANSITION]: 'Redirect',
  [GO_TO_TRANSITION]: 'Go To',
};

export const DEFAULT_BUTTON_TEXT = 'Continue';

export const APP_SETTINGS_PRIMARY_COLOR = 'var(--primary-color)';
export const APP_SETTINGS_PRIMARY_COLOR_DARKEN = 'var(--primary-color-darken)';
export const APP_SETTINGS_PRIMARY_COLOR_TRANSPARENTIZED =
  'var(--primary-color-transparentized)';

export const SUBMISSION_BUTTON_SETTINGS = {
  free_response: {
    showSection: true,
    noSwitcher: true,
    legend: 'Submission Button Settings',
  },
  quick_answer: {
    showSection: false,
  },
  multiple_choice: {
    showSection: true,
    title: 'Customize submission button',
    legend: 'Submission Button Settings',
    text: DEFAULT_BUTTON_TEXT,
    color: APP_SETTINGS_PRIMARY_COLOR,
    noSwitcher: true,
  },
  donation: {
    showSection: true,
    title: 'Customize submission button',
    legend: 'Submission Button Settings',
    text: DEFAULT_BUTTON_TEXT,
    color: APP_SETTINGS_PRIMARY_COLOR,
    noSwitcher: true,
  },
  data_only: {
    showSection: true,
    title: 'Customize submission button',
    legend: 'Submission Button Settings',
    text: DEFAULT_BUTTON_TEXT,
    color: APP_SETTINGS_PRIMARY_COLOR,
    noSwitcher: true,
  },
  content: {
    showSection: true,
    title: 'Show submission button',
    legend: 'Button Settings',
    text: DEFAULT_BUTTON_TEXT,
    color: APP_SETTINGS_PRIMARY_COLOR,
    noSwitcher: false,
  },
};

const buildRichTextObject = (title) => ({
  title,
  titleHtml: `<p><span>${title}</span></p>`,
});

export const DEFAULT_DATA_FIELDS = [
  { type: 'full_name', richText: buildRichTextObject('Full name'), disabled: false, required: false },
  { type: 'first_name', richText: buildRichTextObject('First name'), disabled: false, required: false },
  { type: 'last_name', richText: buildRichTextObject('Last name'), disabled: false, required: false },
  {
    type: 'date_of_birth',
    richText: buildRichTextObject('Date of birth'),
    disabled: false,
    required: false,
  },
  { type: 'email', richText: buildRichTextObject('Email'), disabled: false, required: false },
  { type: 'phone', richText: buildRichTextObject('Phone'), disabled: false, required: false },
  { type: 'address', richText: buildRichTextObject('Address'), disabled: false, required: false },
  { type: 'city', richText: buildRichTextObject('City'), disabled: false, required: false },
  { type: 'state', richText: buildRichTextObject('State / Region'), disabled: false, required: false },
  { type: 'zip_code', richText: buildRichTextObject('Postal / Zip'), disabled: false, required: false },
  { type: 'country', richText: buildRichTextObject('Country'), disabled: false, required: false },
  {
    type: 'currently_employed',
    richText: buildRichTextObject('Currently employed'),
    disabled: false,
    required: false,
  },
  { type: 'company', richText: buildRichTextObject('Company'), disabled: false, required: false },
  { type: 'occupation', richText: buildRichTextObject('Occupation'), disabled: false, required: false },
];

export const DEFAULT_DONATION_DATA_FIELDS = [
  {
    type: 'full_name',
    richText: buildRichTextObject('Full name'),
    disabled: true,
    required: true,
    hidden: false,
  },
  {
    type: 'email',
    richText: buildRichTextObject('Email'),
    disabled: false,
    required: true,
    hidden: false,
  },
];

export const DEFAULT_POLITICAL_DATA_FIELDS = [
  {
    type: 'address',
    richText: buildRichTextObject('Address'),
    disabled: true,
    required: true,
    hidden: false,
  },
  {
    type: 'city',
    richText: buildRichTextObject('City'),
    disabled: true,
    required: true,
    hidden: false,
  },
  {
    type: 'state',
    richText: buildRichTextObject('State / Region'),
    disabled: true,
    required: true,
    hidden: false,
  },
  {
    type: 'zip_code',
    richText: buildRichTextObject('Postal / Zip'),
    disabled: true,
    required: true,
    hidden: false,
  },
  {
    type: 'country',
    richText: buildRichTextObject('Country'),
    disabled: true,
    required: true,
    hidden: false,
  },
  {
    type: 'currently_employed',
    richText: buildRichTextObject('Currently employed'),
    disabled: true,
    required: true,
    hidden: false,
  },
  {
    type: 'company',
    richText: buildRichTextObject('Company'),
    disabled: true,
    required: true,
    hidden: false,
  },
  {
    type: 'occupation',
    richText: buildRichTextObject('Occupation'),
    disabled: true,
    required: true,
    hidden: false,
  },
];

export const ENABLED_POLITICAL_DATA_FIELDS_TYPES = ['email'];

export const DISABLED_POLITICAL_DATA_FIELDS_TYPES = [
  'full_name',
  'address',
  'city',
  'state',
  'zip_code',
  'country',
  'currently_employed',
  'occupation',
  'company',
];

export const POLITICAL_DATA_FIELDS = DEFAULT_DATA_FIELDS.map((field) => {
  if (DISABLED_POLITICAL_DATA_FIELDS_TYPES.includes(field.type)) {
    return {
      ...field,
      required: true,
      disabled: true,
    };
  } else {
    return { ...field };
  }
});

export const POLITICAL_DATA_FIELDS_TYPES = ENABLED_POLITICAL_DATA_FIELDS_TYPES.concat(
  DISABLED_POLITICAL_DATA_FIELDS_TYPES,
);

export const GOAL_AND_METRICS_DEFAULT_VALUES = {
  submissionGoal: 1000,
  submissionGoalName: 'submissions',
  raiseGoal: 5000,
  raiseGoalName: 'raised',
  raiseGoalByAsker: 10000,
  raiseGoalNameByAsker: 'total raised',
  otherGoal: '1000',
  otherGoalAmount: '0',
  otherGoalName: 'voters registered',
  amountRaisedMetric: false,
  amountRaisedMetricName: 'raised',
  amountRaisedByExperienceMetric: false,
  amountRaisedByExperienceMetricName: 'raised',
  amountRaisedByAskerMetric: false,
  amountRaisedByAskerMetricName: 'total raised',
  amountRaisedByVariantMetric: false,
  amountRaisedByVariantMetricName: 'raised',
  submissionsMetric: false,
  submissionsMetricName: 'donors',
  otherMetric: false,
  otherMetricAmount: '2',
  otherMetricName: 'countries',
};

// https://en.wikipedia.org/wiki/ISO_3166-1
export const COUNTRIES_FOR_SELECT = [
  { value: 'AF', name: 'Afghanistan' },
  { value: 'AX', name: 'Åland Islands' },
  { value: 'AL', name: 'Albania' },
  { value: 'DZ', name: 'Algeria' },
  { value: 'AS', name: 'American Samoa' },
  { value: 'AD', name: 'Andorra' },
  { value: 'AO', name: 'Angola' },
  { value: 'AI', name: 'Anguilla' },
  { value: 'AQ', name: 'Antarctica' },
  { value: 'AG', name: 'Antigua and Barbuda' },
  { value: 'AR', name: 'Argentina' },
  { value: 'AM', name: 'Armenia' },
  { value: 'AW', name: 'Aruba' },
  { value: 'AU', name: 'Australia' },
  { value: 'AT', name: 'Austria' },
  { value: 'AZ', name: 'Azerbaijan' },
  { value: 'BS', name: 'Bahamas' },
  { value: 'BH', name: 'Bahrain' },
  { value: 'BD', name: 'Bangladesh' },
  { value: 'BB', name: 'Barbados' },
  { value: 'BY', name: 'Belarus' },
  { value: 'BE', name: 'Belgium' },
  { value: 'BZ', name: 'Belize' },
  { value: 'BJ', name: 'Benin' },
  { value: 'BM', name: 'Bermuda' },
  { value: 'BT', name: 'Bhutan' },
  { value: 'BO', name: 'Bolivia (Plurinational State of)' },
  { value: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'BA', name: 'Bosnia and Herzegovina' },
  { value: 'BW', name: 'Botswana' },
  { value: 'BV', name: 'Bouvet Island' },
  { value: 'BR', name: 'Brazil' },
  { value: 'IO', name: 'British Indian Ocean Territory' },
  { value: 'BN', name: 'Brunei Darussalam' },
  { value: 'BG', name: 'Bulgaria' },
  { value: 'BF', name: 'Burkina Faso' },
  { value: 'BI', name: 'Burundi' },
  { value: 'CV', name: 'Cabo Verde' },
  { value: 'KH', name: 'Cambodia' },
  { value: 'CM', name: 'Cameroon' },
  { value: 'CA', name: 'Canada' },
  { value: 'KY', name: 'Cayman Islands' },
  { value: 'CF', name: 'Central African Republic' },
  { value: 'TD', name: 'Chad' },
  { value: 'CL', name: 'Chile' },
  { value: 'CN', name: 'China' },
  { value: 'CX', name: 'Christmas Island' },
  { value: 'CC', name: 'Cocos (Keeling) Islands' },
  { value: 'CO', name: 'Colombia' },
  { value: 'KM', name: 'Comoros' },
  { value: 'CG', name: 'Congo' },
  { value: 'CD', name: 'Congo (Democratic Republic of the)' },
  { value: 'CK', name: 'Cook Islands' },
  { value: 'CR', name: 'Costa Rica' },
  { value: 'CI', name: "Côte d'Ivoire" },
  { value: 'HR', name: 'Croatia' },
  { value: 'CU', name: 'Cuba' },
  { value: 'CW', name: 'Curaçao' },
  { value: 'CY', name: 'Cyprus' },
  { value: 'CZ', name: 'Czech Republic' },
  { value: 'DK', name: 'Denmark' },
  { value: 'DJ', name: 'Djibouti' },
  { value: 'DM', name: 'Dominica' },
  { value: 'DO', name: 'Dominican Republic' },
  { value: 'EC', name: 'Ecuador' },
  { value: 'EG', name: 'Egypt' },
  { value: 'SV', name: 'El Salvador' },
  { value: 'GQ', name: 'Equatorial Guinea' },
  { value: 'ER', name: 'Eritrea' },
  { value: 'EE', name: 'Estonia' },
  { value: 'ET', name: 'Ethiopia' },
  { value: 'FK', name: 'Falkland Islands (Malvinas)' },
  { value: 'FO', name: 'Faroe Islands' },
  { value: 'FJ', name: 'Fiji (Republic of)' },
  { value: 'FI', name: 'Finland' },
  { value: 'FR', name: 'France' },
  { value: 'GF', name: 'French Guiana' },
  { value: 'PF', name: 'French Polynesia' },
  { value: 'TF', name: 'French Southern Territories' },
  { value: 'GA', name: 'Gabon' },
  { value: 'GM', name: 'Gambia' },
  { value: 'GE', name: 'Georgia' },
  { value: 'DE', name: 'Germany' },
  { value: 'GH', name: 'Ghana' },
  { value: 'GI', name: 'Gibraltar' },
  { value: 'GR', name: 'Greece' },
  { value: 'GL', name: 'Greenland' },
  { value: 'GD', name: 'Grenada' },
  { value: 'GP', name: 'Guadeloupe' },
  { value: 'GU', name: 'Guam' },
  { value: 'GT', name: 'Guatemala' },
  { value: 'GG', name: 'Guernsey' },
  { value: 'GN', name: 'Guinea' },
  { value: 'GW', name: 'Guinea-Bissau' },
  { value: 'GY', name: 'Guyana' },
  { value: 'HT', name: 'Haiti' },
  { value: 'HM', name: 'Heard Island and McDonald Islands' },
  { value: 'VA', name: 'Holy See' },
  { value: 'HN', name: 'Honduras' },
  { value: 'HK', name: 'Hong Kong' },
  { value: 'HU', name: 'Hungary' },
  { value: 'IS', name: 'Iceland' },
  { value: 'IN', name: 'India' },
  { value: 'ID', name: 'Indonesia' },
  { value: 'IR', name: 'Iran (Islamic Republic of)' },
  { value: 'IQ', name: 'Iraq' },
  { value: 'IE', name: 'Ireland' },
  { value: 'IM', name: 'Isle of Man' },
  { value: 'IL', name: 'Israel' },
  { value: 'IT', name: 'Italy' },
  { value: 'JM', name: 'Jamaica' },
  { value: 'JP', name: 'Japan' },
  { value: 'JE', name: 'Jersey' },
  { value: 'JO', name: 'Jordan' },
  { value: 'KZ', name: 'Kazakhstan' },
  { value: 'KE', name: 'Kenya' },
  { value: 'KI', name: 'Kiribati' },
  { value: 'KP', name: "Korea (Democratic People's Republic of)" },
  { value: 'KR', name: 'Korea (Republic of)' },
  { value: 'KW', name: 'Kuwait' },
  { value: 'KG', name: 'Kyrgyzstan' },
  { value: 'LA', name: "Lao People's Democratic Republic" },
  { value: 'LV', name: 'Latvia' },
  { value: 'LB', name: 'Lebanon' },
  { value: 'LS', name: 'Lesotho' },
  { value: 'LR', name: 'Liberia' },
  { value: 'LY', name: 'Libya' },
  { value: 'LI', name: 'Liechtenstein' },
  { value: 'LT', name: 'Lithuania' },
  { value: 'LU', name: 'Luxembourg' },
  { value: 'MO', name: 'Macao' },
  { value: 'MK', name: 'Macedonia (the former Yugoslav Republic of)' },
  { value: 'MG', name: 'Madagascar' },
  { value: 'MW', name: 'Malawi' },
  { value: 'MY', name: 'Malaysia' },
  { value: 'MV', name: 'Maldives' },
  { value: 'ML', name: 'Mali' },
  { value: 'MT', name: 'Malta' },
  { value: 'MH', name: 'Marshall Islands' },
  { value: 'MQ', name: 'Martinique' },
  { value: 'MR', name: 'Mauritania' },
  { value: 'MU', name: 'Mauritius' },
  { value: 'YT', name: 'Mayotte' },
  { value: 'MX', name: 'Mexico' },
  { value: 'FM', name: 'Micronesia (Federated States of)' },
  { value: 'MD', name: 'Moldova (Republic of)' },
  { value: 'MC', name: 'Monaco' },
  { value: 'MN', name: 'Mongolia' },
  { value: 'ME', name: 'Montenegro' },
  { value: 'MS', name: 'Montserrat' },
  { value: 'MA', name: 'Morocco' },
  { value: 'MZ', name: 'Mozambique' },
  { value: 'MM', name: 'Myanmar' },
  { value: 'NA', name: 'Namibia' },
  { value: 'NR', name: 'Nauru' },
  { value: 'NP', name: 'Nepal' },
  { value: 'NL', name: 'Netherlands' },
  { value: 'NC', name: 'New Caledonia' },
  { value: 'NZ', name: 'New Zealand' },
  { value: 'NI', name: 'Nicaragua' },
  { value: 'NE', name: 'Niger' },
  { value: 'NG', name: 'Nigeria' },
  { value: 'NU', name: 'Niue' },
  { value: 'NF', name: 'Norfolk Island' },
  { value: 'MP', name: 'Northern Mariana Islands' },
  { value: 'NO', name: 'Norway' },
  { value: 'OM', name: 'Oman' },
  { value: 'PK', name: 'Pakistan' },
  { value: 'PW', name: 'Palau' },
  { value: 'PS', name: 'Palestine, State of' },
  { value: 'PA', name: 'Panama' },
  { value: 'PG', name: 'Papua New Guinea' },
  { value: 'PY', name: 'Paraguay' },
  { value: 'PE', name: 'Peru' },
  { value: 'PH', name: 'Philippines' },
  { value: 'PN', name: 'Pitcairn' },
  { value: 'PL', name: 'Poland' },
  { value: 'PT', name: 'Portugal' },
  { value: 'PR', name: 'Puerto Rico' },
  { value: 'QA', name: 'Qatar' },
  { value: 'RE', name: 'Réunion' },
  { value: 'RO', name: 'Romania' },
  { value: 'RU', name: 'Russian Federation' },
  { value: 'RW', name: 'Rwanda' },
  { value: 'BL', name: 'Saint Barthélemy' },
  { value: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'KN', name: 'Saint Kitts and Nevis' },
  { value: 'LC', name: 'Saint Lucia' },
  { value: 'MF', name: 'Saint Martin (French part)' },
  { value: 'PM', name: 'Saint Pierre and Miquelon' },
  { value: 'VC', name: 'Saint Vincent and the Grenadines' },
  { value: 'WS', name: 'Samoa' },
  { value: 'SM', name: 'San Marino' },
  { value: 'ST', name: 'Sao Tome and Principe' },
  { value: 'SA', name: 'Saudi Arabia' },
  { value: 'SN', name: 'Senegal' },
  { value: 'RS', name: 'Serbia' },
  { value: 'SC', name: 'Seychelles' },
  { value: 'SL', name: 'Sierra Leone' },
  { value: 'SG', name: 'Singapore' },
  { value: 'SX', name: 'Sint Maarten (Dutch part)' },
  { value: 'SK', name: 'Slovakia' },
  { value: 'SI', name: 'Slovenia' },
  { value: 'SB', name: 'Solomon Islands' },
  { value: 'SO', name: 'Somalia' },
  { value: 'ZA', name: 'South Africa' },
  { value: 'GS', name: 'South Georgia and the South Sandwich Islands' },
  { value: 'SS', name: 'South Sudan' },
  { value: 'ES', name: 'Spain' },
  { value: 'LK', name: 'Sri Lanka' },
  { value: 'SD', name: 'Sudan' },
  { value: 'SR', name: 'Suriname' },
  { value: 'SJ', name: 'Svalbard and Jan Mayen' },
  { value: 'SZ', name: 'Swaziland' },
  { value: 'SE', name: 'Sweden' },
  { value: 'CH', name: 'Switzerland' },
  { value: 'SY', name: 'Syrian Arab Republic' },
  { value: 'TW', name: 'Taiwan, Province of China' },
  { value: 'TJ', name: 'Tajikistan' },
  { value: 'TZ', name: 'Tanzania, United Republic of' },
  { value: 'TH', name: 'Thailand' },
  { value: 'TL', name: 'Timor-Leste' },
  { value: 'TG', name: 'Togo' },
  { value: 'TK', name: 'Tokelau' },
  { value: 'TO', name: 'Tonga' },
  { value: 'TT', name: 'Trinidad and Tobago' },
  { value: 'TN', name: 'Tunisia' },
  { value: 'TR', name: 'Turkey' },
  { value: 'TM', name: 'Turkmenistan' },
  { value: 'TC', name: 'Turks and Caicos Islands' },
  { value: 'TV', name: 'Tuvalu' },
  { value: 'UG', name: 'Uganda' },
  { value: 'UA', name: 'Ukraine' },
  { value: 'AE', name: 'United Arab Emirates' },
  { value: 'GB', name: 'United Kingdom of Great Britain and Northern Ireland' },
  { value: 'US', name: 'United States of America' },
  { value: 'UM', name: 'United States Minor Outlying Islands' },
  { value: 'UY', name: 'Uruguay' },
  { value: 'UZ', name: 'Uzbekistan' },
  { value: 'VU', name: 'Vanuatu' },
  { value: 'VE', name: 'Venezuela (Bolivarian Republic of)' },
  { value: 'VN', name: 'Vietnam' },
  { value: 'VG', name: 'Virgin Islands (British)' },
  { value: 'VI', name: 'Virgin Islands (U.S.)' },
  { value: 'WF', name: 'Wallis and Futuna' },
  { value: 'EH', name: 'Western Sahara' },
  { value: 'YE', name: 'Yemen' },
  { value: 'ZM', name: 'Zambia' },
  { value: 'ZW', name: 'Zimbabwe' },
];

export const ASK_EDIT_SM_BREAKPOINT = 800;
export const SM_BREAKPOINT = 600;

export const ALL_ALLOWED_FILE_TYPES =
  'image/jpeg,image/pjpeg,image/png,image/gif,video/mp4,video/webm,video/quicktime,video/mpeg,video/x-flv,video/avi,video/msvideo,video/x-msvideo,video/x-mpeg,video/x-ms-wmv,video/ms-asf,video/x-matroska,application/msword,application/x-troff-msvideo,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/excel,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/plain,application/rtf,application/x-rtf,text/richtext,text/rtf,text/html,application/x-compressed,application/x-zip-compressed,application/zip,multipart/x-zip,audio/mpeg3,audio/x-mpeg-3,audio/x-ms-wma,audio/mp3,audio/mpeg';
export const PREVIEWABLE_VIDEO_TYPES = [
  'video/mp4',
  'video/webm',
  'video/quicktime',
];
export const ALLOWED_IMAGE_TYPES = [
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/gif',
];
export const ALLOWED_VIDEO_TYPES = [
  ...PREVIEWABLE_VIDEO_TYPES,
  'video/mpeg',
  'video/x-flv',
  'video/avi',
  'video/msvideo',
  'video/x-msvideo',
  'video/x-mpeg',
  'video/x-ms-wmv',
  'video/ms-asf',
  'video/x-matroska',
];

export const DEFAULT_ASK_LABELS = {
  free_response: {
    title: 'Respond with:',
    titleElements: '[{"type":"heading-3","children":[{"text":"Respond with:"}],"align":"center"}]',
    titleHtml: '<h3 style="text-align: center;">Respond with:</h3>',
  },
  donation: {
    title: '',
    titleElements: '[{"type":"paragraph","children":[{"text":""}]}]',
    titleHtml: '<p><span></span></p>',
  },
  multiple_choice: {
    title: 'Select one',
    titleElements: '[{"type":"paragraph","children":[{"text":"Select one","fontWeight":"500"}]}]',
    titleHtml: '<p><span style="font-weight: 500;">Select one</span></p>',
  },
  multiple_choice_multi: {
    title: 'Select one or more',
    titleElements: '[{"type":"paragraph","children":[{"text":"Select one or more","fontWeight":"500"}]}]',
    titleHtml: '<p><span style="font-weight: 500;">Select one or more</span></p>',
  },
  data_only: {
    title: '',
    titleElements: '[{"type":"paragraph","children":[{"text":""}]}]',
    titleHtml: '<p><span></span></p>',
  },
  quick_answer: {
    title: 'Answer with:',
    titleElements: '[{"type":"heading-3","children":[{"text":"Answer with:"}],"align":"center"}]',
    titleHtml: '<h3 style="text-align: center;">Answer with:</h3>',
  },
  content: {
    title: '',
    titleElements: '[{"type":"paragraph","children":[{"text":""}]}]',
    titleHtml: '<p><span></span></p>',
  },
};

