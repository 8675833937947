import React, { useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { mergeFontsByName } from '../utils';

const BlockContext = React.createContext();

BlockContext.displayName = 'Block Context';

export const FONT_WEIGHT_MENU_ITEMS = [
  { value: 100, label: 'Thin' },
  { value: 200, label: 'ExtraLight' },
  { value: 300, label: 'Light' },
  { value: 400, label: 'Regular' },
  { value: 500, label: 'Medium' },
  { value: 600, label: 'SemiBold' },
  { value: 700, label: 'Bold' },
  { value: 800, label: 'ExtraBold' },
  { value: 900, label: 'Black' },
];

export function Context({ children, blockFormProps, workspaceSettings }) {
  const allFonts = workspaceSettings?.fonts || [];
  const mergedFonts = mergeFontsByName(allFonts);
  const currentFontFamily = allFonts.find(
    (f) => f.font_family === blockFormProps?.block.font_family,
  );

  const fontsSelectItems = mergedFonts.map((f) => ({ value: f.font_family, label: f.name }));

  const fontWeightsSelectItems = FONT_WEIGHT_MENU_ITEMS.map((item) => ({
    ...item,
    disabled: !currentFontFamily?.font_weights.includes(item.value),
  }));

  const dataHook = useState({
    titleHtml: '',
    ctaButtons: blockFormProps?.block.cta_buttons,
    askLabel: blockFormProps?.block.ask_label,
    initialValues: {
      blockType: blockFormProps?.block.type,
      askLabel: blockFormProps?.block.ask_label,
    },
    titleSetting: blockFormProps?.block.title_setting,
    navigationButtons: blockFormProps?.block.navigation_buttons,
    showNavigationButtons: !_isEmpty(blockFormProps?.block.navigation_buttons),
    contentLayout: blockFormProps?.block.content_layout,
    contentVerticalAlignment: blockFormProps?.block.content_vertical_alignment,
    hugContentFontSize: blockFormProps?.block.hug_content_font_size,
    connectAccountCapabilities: workspaceSettings?.connectAccountCapabilities,
    fontsList: mergedFonts,
    fontsSelectItems,
    fontWeightsSelectItems,
    paletteColors: workspaceSettings?.paletteColors,
  });

  return (
    <BlockContext.Provider value={dataHook}>{children}</BlockContext.Provider>
  );
}

export default BlockContext;
