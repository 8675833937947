import makeToast from './components/toast';

/**
 * Util functions
 */
const BROWSER_MATCHER = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;
const BROWSER_VERSION_MATCHER = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i;
export const isMobile = () => {
  const agent = navigator.userAgent || navigator.vendor || window.opera;

  return !!(
    BROWSER_MATCHER.test(agent) ||
    BROWSER_VERSION_MATCHER.test(agent.substr(0, 4))
  );
};

export const getMobileOS = () => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return 'Android';
  } else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return 'iOS';
  }
  return 'Other';
};

export const hideElement = (element) => {
  element.classList.add('hidden');
};

export const toggleElement = (element) => {
  element.classList.toggle('hidden');
};

export const showElement = (element) => {
  element.classList.remove('hidden');
};

export const isHiddenElement = (element) => {
  return element.classList.contains('hidden');
};

export const isShownElement = (element) => {
  return !isHiddenElement(element);
};

export const redirectTo = (link) => {
  window.location.href = link;
};

export function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
    : Math.sign(num) * Math.abs(num);
}

export function commaFormatter(val) {
  if (val != undefined || val != null) {
    while (/(\d+)(\d{3})/.test(val.toString())) {
      val = val.toString().replace(/(\d+)(\d{3})/, '$1,$2');
    }

    return val;
  } else {
    return '';
  }
}

export const maybePluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const getErrorMessage = (errors, key) => errors[key] && errors[key][0];

export const insertIntoArrayAtIndex = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const reorderArrayByIndexes = (array, indexFrom, indexTo) => {
  if (!array.length) {
    return array;
  }

  const result = Array.from(array);
  const [removed] = result.splice(indexFrom, 1);

  if (!removed) {
    return result;
  }

  result.splice(indexTo, 0, removed);

  return result;
};

export const toHumanReadableTime = (value, twelweHourFormat = true) => {
  const date = new Date(value);
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: twelweHourFormat,
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const setClassName = (className, value) => {
  return `${value ? className : ''}`;
};

export const showNetworkErrorMessage = (err) => {
  if (err.message === 'Failed to fetch') {
    makeToast(
      'Please check your internet connection or try again later',
      'error',
    );
  }
};

export const flatifyJson = (data) => {
  return data.map((item) => {
    let localItem = {};
    localItem = item.attributes;
    if (item.links) {
      localItem.links = item.links;
    }

    return localItem;
  });
};

export const toHtmlWithStyledReferences = (arr) => {
  return arr
    .map((text, index) => {
      if (typeof text === 'string') {
        if (text.match(/{{(#|\/).*}}/)) {
          return `<span class="preview_reference">${text.slice(3, -2)}</span>`;
        } else {
          return text;
        }
      }
      if (arr[index - 1].includes('<a href="')) {
        return text.reference;
      }

      return `<span class="preview_reference">${text.name}</span>`;
    })
    .join('');
};

export const rgbaObjToString = (value) => {
  if (typeof(value) === 'string') {
    return value;
  }
  return `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, ${value.rgb.a})`;
};

export const hexToRGB = (hex, alpha) => {
  if (!hex) {
    return '';
  }

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};

export const buildDefaultRichObject = (value) =>({
  title: value,
  titleHtml: `<p><span>${value}</span></p>`,
  titleElements: `[{"type":"paragraph","children":[{"text":"${value}"}]}]`,
});

export const mergeFontsByName = (fonts) => {
  return fonts.reduce((acc, font) => {
    const existingFont = acc.find((f) => f.name === font.name);
    if (existingFont) {
      existingFont.font_weights = [
        ...existingFont.font_weights,
        ...font.font_weights,
      ];
    } else {
      acc.push(font);
    }
    return acc;
  }, []);
};
